<template>
  <div>
    <PageBar Image="img/hr_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>
    <div class="main">
      <div class="filterCol">
        <div class="keyWordSearch">
          <h3>關鍵字搜尋</h3>
          <input type="text" v-model="KeywordInput" />
          <button class="searchBtn" @click="searchKeyword">查詢</button>
        </div>
        <div class="periodSearch">
          <h3>時間區段搜尋</h3>
          <div class="dateFrom">
            從
            <input type="date" id="start" />
          </div>
          <div class="dateTo">
            至
            <input type="date" id="end" />
          </div>
          <button class="searchBtn" @click="searchDay">查詢</button>
        </div>
        <h3>專業領域分類</h3>
        <div class="collapse inTalent">
          <div class="flle">
            <span class="select_all" @click="selectAll">全選</span>
            <span class="unselect_all" @click="unselectAll">全不選</span>
          </div>
          <span class="hide_all">全部收合</span><span class="show_all">全部展開</span>
        </div>
        <div class="courseType">
          <ul class="layer1">
            <template v-for="c in Categories">
              <li v-if="c.Show" :key="c.TalentCategoryID" :class="{
                on: SelectedCategories.indexOf(c.TalentCategoryID) >= 0,
              }">
                <h4>
                  <input type="checkbox" v-model="SelectedCategories" :value="c.TalentCategoryID"
                    onclick="window.model.toggle(this)" />{{ c.CategoryName
                  }}<img class="arrDown" src="img/arr_down.svg" />
                </h4>
                <ul class="layer2">
                  <template v-for="c1 in c.Children">
                    <li v-if="c1.Show" :key="c1.TalentCategoryID" class="open">
                      <h4>
                        <input type="checkbox" v-model="SelectedCategories" :value="c1.TalentCategoryID"
                          onclick="window.model.toggle(this)" />{{ c1.CategoryName
                        }}<img class="arrDown" src="img/arr_down.svg" />
                      </h4>
                      <ul class="layer3">
                        <template v-for="c2 in c1.Children">
                          <li v-if="c2.Show" :key="c2.TalentCategoryID">
                            <h4>
                              <input type="checkbox" v-model="SelectedCategories" :value="c2.TalentCategoryID"
                                onclick="window.model.toggle(this)" />{{ c2.CategoryName
                              }}<img class="arrDown" src="img/arr_down.svg" />
                            </h4>
                          </li>
                        </template>
                      </ul>
                    </li>
                  </template>
                </ul>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="coursesContainer">
        <h2>中英文專業人才庫</h2>
        <div class="sortBy">
          <span>排序依</span>
          <select v-model="SortBy">
            <option value="default" v-if="false">發布日期</option>
            <option value="price_desc">職稱筆劃多至少</option>
            <option value="price_asc">職稱筆劃少至多</option>
            <option value="author_desc">姓名筆劃多至少</option>
            <option value="author_asc">姓名筆劃少至多</option>
          </select>
        </div>
        <div class="courseList c_list">
          <div class="list_style">
            <table>
              <tr>
                <th style="width: 80px">姓名</th>
                <th>職稱</th>
                <th>地區</th>
                <th>學經歷</th>
                <th>相關專業</th>
              </tr>
              <tr v-for="l in Talents" :key="l.TalentID">
                <td>
                  <a href="javascript:void(0);" @click="go(l)">{{ l.Name }}</a>
                </td>
                <td>{{ l.Title }}</td>
                <td>{{ l.Area | area }}</td>
                <td>{{ l.Experience }}</td>
                <td>{{ l.Profession }}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="pagination">
          <div class="prev10" v-if="ShowPrev10">
            <img src="img/prev10.png" alt="" @click="prev10" />
          </div>
          <ul>
            <li v-for="p in Pages" :key="p">
              <a :class="{ act: p == Page }" href="javascript:void(0);" @click="showPage(p)">{{ p }}</a>
            </li>
          </ul>
          <div class="next10" v-if="ShowNext10">
            <img src="img/next10.png" alt="" @click="next10" />
          </div>
        </div>
      </div>
      <div class="typeMapTrigger" @click="showLearning">專業領域分類架構圖</div>
    </div>
    <div class="popUp hide" id="firstLearning">
      <div class="alertWindow firstLearning typeMap">
        <div class="p_head">
          <p>專業領域分類架構圖</p>
        </div>
        <div class="p_content">
          <img src="img/1652973714133.jpg" alt="" />
          <p class="knownCheck">
            <label for="known">
              <input type="checkbox" id="known" name="known" value="known" @change="known" />
              我已經了解，下次別再通知我囉！
            </label>
          </p>
        </div>
        <div class="p_foot">
          <a href="javascript:void(0)" @click="closeFirstDialog">關閉</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import moment from "moment";
import user from "../plugins/user";

function initial() {
  $(function () {
    if ($(window).width() > 900) {
      //Trigger layout after each image loads and initialise Mansonry

      $("#waterfallArea").imagesLoaded(function () {
        $("#waterfallArea").masonry({
          itemSelector: ".content_box",
          animate: true,
          horizontalOrder: true,
          originTop: true,
        });
      });

      //Fade Out the loading screen when all images loaded
      $("#waterfallArea")
        .imagesLoaded()
        .always(function (instance) {
          $(".loadingScreen").fadeOut();
        });
    }
  });

  (function ($) {
    $(window).on("load", function () {
      $(".scrollContent").mCustomScrollbar();
    });
  })(jQuery);

  $(".logos_slide").slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  });

  $(".layer1>li>h4>input").click(function () {
    if ($(this).parents(".layer1>li").hasClass("on")) {
      // $(this).removeClass("on");
      $(this).parents(".layer1>li").removeClass("on");
    } else {
      $(this).parents(".layer1>li").addClass("on");
    }
  });
  $(".layer2>li>h4>input").click(function () {
    if ($(this).parents(".layer2>li").hasClass("on")) {
      // $(this).removeClass("on");
      $(this).parents(".layer2>li").removeClass("on");
    } else {
      $(this).parents(".layer2>li").addClass("on");
      $(this).parents(".layer2>li").addClass("on");
    }
  });
  $(".layer3>li>h4>input").click(function () {
    if ($(this).parents(".layer3>li").hasClass("on")) {
      // $(this).removeClass("on");
      $(this).parents(".layer3>li").removeClass("on");
    } else {
      $(this).parents(".layer3>li").addClass("on");
    }
  });

  $(".layer1>li>h4>.arrDown").click(function () {
    if ($(this).parents(".layer1>li").hasClass("open")) {
      // $(this).removeClass("open");
      $(this).parents(".layer1>li").removeClass("open");
    } else {
      $(".layer1>li").removeClass("open");
      $(this).parents(".layer1>li").addClass("open");
    }
  });
  $(".layer2>li>h4>.arrDown").click(function () {
    if ($(this).parents(".layer2>li").hasClass("open")) {
      // $(this).removeClass("open");
      $(this).parents(".layer2>li").removeClass("open");
    } else {
      $(".layer2>li").removeClass("open");
      $(this).parents(".layer2>li").addClass("open");
      $(this).parents(".layer2>li").addClass("open");
    }
  });
  $(".layer3>li>h4>.arrDown").click(function () {
    if ($(this).parents(".layer3>li").hasClass("open")) {
      // $(this).removeClass("open");
      $(this).parents(".layer3>li").removeClass("open");
    } else {
      $(this).parents(".layer3>li").addClass("open");
    }
  });

  $(".show_all").click(function () {
    $(".courseType li").addClass("open");
  });
  $(".hide_all").click(function () {
    $(".courseType li").removeClass("open");
  });
}

const PageSize = 8;

var map = {};
var all = [];
var result;
var fmap = {};
var auths = {};
var uauths = {};
var allcats = [];

var mode = "icon";

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Categories: [],
      Talents: [],
      Page: 1,
      MaxPage: 0,
      Pages: [],
      SortBy: "author_asc",
      Keyword: "",
      KeywordInput: "",
      StartDay: "",
      EndDay: "",
      SelectedCategories: [],
    };
  },
  mounted() {
    window.model = this;
    this.setBase("media_platform");
    if (user.user) {
      user.user.type.forEach((u) => (uauths[u] = true));
    } else {
      uauths[-1] = true;
    }
    this.load();
  },
  methods: {
    async load() {
      result = await this.$api.getTalents();

      if (user.user) {
        var favs = await this.$api.getFavorite();
        fmap = {};
        favs.forEach((f) => (fmap[f] = true));
      }

      var cats = result.Categories.sort((a, b) => a.Sort - b.Sort);
      var root = { Children: [], TalentCategoryID: null };
      this.createCategory(cats, root);
      this.checkCategoryAuth(root.Children);
      this.Categories = root.Children;

      all = [];
      result.Talents.forEach((l) => {
        l.IsFavorite = fmap[l.TalentID];
        if (auths[l.TalentCategoryID]) {
          all.push(l);
          map[l.TalentID] = l;
        }
      });
      this.show(1);

      this.Breadcrumbs = [{ Title: "中英文專業人才庫", Url: "#" }];

      setTimeout(() => {
        initial();
      });
      this.loadCustomJs();

      if (!localStorage.getItem("firstTalent")) {
        $("#firstLearning").removeClass("hide");
        return;
      }
    },
    showLearning() {
      $(".knownCheck").hide();
      $("#firstLearning").removeClass("hide");
    },
    closeFirstDialog() {
      $("#firstLearning").addClass("hide");
    },
    known() {
      localStorage.setItem("firstLearning", $("#known").prop("checked"));
    },
    createCategory(Category, parent) {
      allcats.push(Category.TalentCategoryID);
      Category.forEach((m) => {
        if (m.ParentTalentCategoryID == parent.TalentCategoryID) {
          if (!parent.Children) {
            parent.Children = [];
          }
          parent.Children.push(m);
          this.createCategory(Category, m);
        }
      });
    },
    checkCategoryAuth(Category) {
      Category.forEach((m) => {
        if (!m.Auths) return;
        var ahs = m.Auths.split(",");
        for (var i in ahs) {
          m.Show = uauths[ahs[i]];
          if (m.Show) {
            auths[m.TalentCategoryID] = true;
            break;
          }
        }
        if (m.Show && m.Children) {
          this.checkCategoryAuth(m.Children);
        }
      });
    },
    selectAll() {
      $("input", $(".courseType"))
        .get()
        .forEach((x) => {
          if (!$(x).prop("checked")) {
            $(x).prop("checked", true);
            let el = $(x).get(0);
            let event = document.createEvent("Events");
            event.initEvent("change", true, false);
            el.dispatchEvent(event);
          }
        });
    },
    unselectAll() {
      $("input", $(".courseType"))
        .get()
        .forEach((x) => {
          if ($(x).prop("checked")) {
            $(x).prop("checked", false);
            let el = $(x).get(0);
            let event = document.createEvent("Events");
            event.initEvent("change", true, false);
            el.dispatchEvent(event);
          }
        });
    },
    showPage(page) {
      this.Page = page;
      this.show();
    },
    searchKeyword() {
      this.Keyword = this.KeywordInput;
      this.StartDay = null;
      this.EndDay = null;
      this.showPage(1);
    },
    searchDay() {
      this.Keyword = null;
      this.StartDay = $("#start").val();
      this.EndDay = $("#end").val();
      this.showPage(1);
    },
    toggle(c) {
      if ($(c).prop("checked")) {
        $("input", $(c).parent().siblings()).prop("checked", true);
        $("input", $(c).parent().siblings())
          .get()
          .forEach((cc) => {
            let el = $(cc).get(0);
            let event = document.createEvent("Events");
            event.initEvent("change", true, false);
            el.dispatchEvent(event);
          });
        $("li", $(c).parent().siblings()).addClass("on");
      } else {
        $("input", $(c).parent().siblings()).prop("checked", false);
        $("input", $(c).parent().siblings())
          .get()
          .forEach((cc) => {
            let el = $(cc).get(0);
            let event = document.createEvent("Events");
            event.initEvent("change", true, false);
            el.dispatchEvent(event);
          });
        $("li", $(c).parent().siblings()).addClass("on");
      }
    },
    go(talent) {
      this.$router.push(`/talent/${talent.TalentID}`);
    },
    known() {
      localStorage.setItem("firstTalent", $("#known").prop("checked"));
    },
    show() {
      var start = (this.Page - 1) * PageSize;
      var sort = this.SortBy;
      var list = [];
      if (sort == "default") {
        list = all;
      } else if (sort == "price_asc") {
        result.SortedByPrice.forEach((id) => list.push(map[id]));
      } else if (sort == "price_desc") {
        result.SortedByPrice.forEach((id) => list.push(map[id]));
        list.reverse();
      } else if (sort == "author_asc") {
        result.SortedByAuthor.forEach((id) => list.push(map[id]));
      } else if (sort == "author_desc") {
        result.SortedByAuthor.forEach((id) => list.push(map[id]));
        list.reverse();
      }
      if (this.Keyword) {
        list = list.filter(
          (x) =>
            x.Title.indexOf(this.Keyword) >= 0 ||
            x.Name.indexOf(this.Keyword) >= 0 ||
            x.Profession.indexOf(this.Keyword) >= 0
        );
      }
      if (this.StartDay) {
        list = list.filter((x) => moment(x.CreateTime).isAfter(this.StartDay));
      }
      if (this.EndDay) {
        list = list.filter((x) => moment(x.CreateTime).isBefore(this.EndDay));
      }
      if (this.SelectedCategories.length > 0) {
        list = list.filter((x) => {
          var found = false;
          this.SelectedCategories.forEach((sc) => {
            found = found || x.Categories.includes(sc);
          });
          return found;
        });
      }

      this.Pages = [];
      this.MaxPage = Math.ceil(list.length / PageSize);
      if (this.MaxPage < this.Page && this.MaxPage >= 1) {
        this.Page = 1;
        this.show();
        return;
      }
      var basePage = Math.floor(this.Page / 10) * 10;
      for (var p = 1; p <= 10; p++) {
        if (this.Page % 10 == 0) {
          basePage = Math.floor((this.Page - 1) / 10) * 10;
        }
        var page = basePage + p;
        if (page > this.MaxPage) {
          break;
        }
        this.Pages.push(page);
      }

      console.log(start);
      console.log(list);
      this.Talents = list.slice(start, start + PageSize);
      setTimeout(() => {
        try {
          $("#waterfallArea").masonry("reloadItems");
        } catch (e) { }
      });
    },
    next10() {
      var p = this.Pages[this.Pages.length - 1] + 1;
      if (p < this.MaxPage) {
        this.showPage(p);
      } else {
        alert("沒有下十頁了");
      }
    },
    prev10() {
      var p = this.Pages[0] - 1;
      if (p > 0) {
        this.showPage(p);
      } else {
        alert("沒有上十頁了");
      }
    },
    async addFavorite(talent) {
      if (!user.user) {
        alert("請先登入");
        return;
      }
      await this.$api.addFavoriteTalent(talent.TalentID);
      talent.IsFavorite = true;
    },
    async delFavorite(talent) {
      if (!user.user) {
        alert("請先登入");
        return;
      }
      await this.$api.removeFavoriteTalent(talent.TalentID);
      talent.IsFavorite = false;
    },
    switchMode() {
      $(".c_" + mode).addClass("hide");
      if (mode == "icon") {
        mode = "list";
        $(".list_icon").addClass("on");
      } else {
        mode = "icon";
        $(".list_icon").removeClass("on");
      }
      $(".c_" + mode).removeClass("hide");
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
    SortBy(newValue, oldValue) {
      this.show();
    },
    SelectedCategories(newValue, oldValue) {
      this.Page = 1;
      this.show();
    },
  },
  filters: {
    area(value) {
      return ["北", "中", "南", "東", "離島"][value];
    },
  },
  computed: {
    ShowNext10() {
      return parseInt(this.Pages[this.Pages.length - 1]) + 1 < this.MaxPage;
    },
    ShowPrev10() {
      return parseInt(this.Pages[0]) > 10;
    },
  },
};
</script>
